import Cookies from "universal-cookie";

function GetTracker(name, value) {
    const cookies = new Cookies();

    if(cookies.get('tracker')) {
        const tracker = Buffer.from(cookies.get('tracker'), 'base64').toString();

        if(tracker.includes(JSON.stringify({'name' : name, 'value' : value}))) {
            return true;
        }

        return false;
    }

    return false;
}

export default GetTracker;