import Cookies from "universal-cookie";

import GetCookie from "./getcookie";
import CreateCookie from "./createcookie";

function CreateTracker(data) {
    const check = GetCookie('tracker');
    const cookies = new Cookies();

    const d = new Date();
    const expiring = d.setDate(d.getDate() + parseInt(365));
    const expires = new Date(expiring);

    if( check ) {
        const tracker = Buffer.from(cookies.get('tracker'), 'base64').toString();
        let trackerArray = JSON.parse(tracker);
        let array = [];

        trackerArray.forEach(function (i, v) {
            array.push({'name': v.name, 'value': v.value});
        });

        array.push({
            'name' : data.name,
            'value' : data.value
        });

        let value = Buffer.from(JSON.stringify(array)).toString('base64');
        CreateCookie('tracker', value, expires);
    } else {
        let array = [];
        array.push({'name': data.name,'value': data.value});
        let value = Buffer.from(JSON.stringify(array)).toString('base64');
        CreateCookie('tracker', value, expires);
    }
}

export default CreateTracker;