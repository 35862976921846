import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import CreateTracker from "../functions/createtracker";

let englishArray = {
    'title': 'Thanks for using the Teacher\'s hub!',
    'para': 'Please take a moment to answer the questions below. Your feedback is valuable and helps us improve our content and resources to make them better for you.',
    'label1': 'How would you rate the children\'s engagement with the activity?',
    'label2': 'How would you rate the educational value of the activity?',
    'label3': 'Would you recommend this activity to others?',
    'label4': 'Please leave any additional feedback which you think<br />might help us develop materials in future.',
    'message': 'Please type your review in this box...',
    'submit': 'Submit',
    'bad': 'Bad',
    'good': 'Good',
    'excellent': 'Excellent',
    'yes': 'Yes',
    'no': 'No'
};

let welshArray = {
    'title': 'Diolch am ddefnyddio\'r Hwb Athrawon!',
    'para': 'Cymerwch funud i ateb y cwestiynau isod. Mae eich adborth yn werthfawr ac yn ein helpu i wella ein cynnwys ac adnoddau i\'w gwneud nhw\'n well i chi.',
    'label1': 'Sut byddech chi\'n graddio ymgysylltiad y plant â\'r gweithgaredd?',
    'label2': 'Sut byddech chi\'n graddio gwerth addysgol y gweithgaredd?',
    'label3': 'A fyddech chi\'n argymell y gweithgaredd hwn i bobl eraill?',
    'label4': 'Nodwch unrhyw sylwadau ychwanegol yr ydych chi\'n<br>credu y gallai ein helpu i ddatblygu deunyddiau yn y dyfodol.',
    'message': 'Teipiwch eich adolygiad yn y blwch hwn...',
    'submit': 'Cyflwyno',
    'bad': 'Gwael',
    'good': 'Da',
    'excellent': 'Gwych',
    'yes': 'Byddwn',
    'no': 'Na fyddwn'
};

function Largepopup({ lang }) {

    function randomNumber() {
        return Math.floor(Math.random() * 101) + 10
    }

    function randomEmailAddress(length = randomNumber()) {
        let result = [];
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('') + '@example.com';
    }

    function submitForm(e) {

        let valid = true;
        let cdActivityEngagement = document.getElementsByName('cd_ACTIVITYENGAGEMENT');
        let cdActivityValue = document.getElementsByName('cd_ACTIVITYVALUE');
        let cdRecommendChoice = document.getElementsByName('cd_RECOMMEND');

        for (var ae = 0; ae < cdActivityEngagement.length; ae++) {
            if(cdActivityEngagement[ae].checked === false) {
                valid = false;
                if (!document.getElementById('activityEngagement').classList.contains('large-popup--invalid__active')) {
                    document.getElementById('activityEngagement').className += ' large-popup--invalid__active';
                }
            } else {
                valid = true;
                document.getElementById('activityEngagement').classList.remove('large-popup--invalid__active');
                break;
            }
        }

        for (var av = 0; av < cdActivityValue.length; av++) {
            if(!cdActivityValue[av].checked) {
                valid = false;
                if (!document.getElementById('activityValue').classList.contains('large-popup--invalid__active')) {
                    document.getElementById('activityValue').className += ' large-popup--invalid__active';
                }
            } else {
                valid = true;
                document.getElementById('activityValue').classList.remove('large-popup--invalid__active');
                break;
            }
        }

        for (var rc = 0; rc < cdRecommendChoice.length; rc++) {
            if(!cdRecommendChoice[rc].checked) {
                valid = false;
                if (!document.getElementById('recommendChoice').classList.contains('large-popup--invalid__active')) {
                    document.getElementById('recommendChoice').className += ' large-popup--invalid__active';
                }
            } else {
                valid = true;
                document.getElementById('recommendChoice').classList.remove('large-popup--invalid__active');
                break;
            }
        }

        if(valid === false) {
            e.preventDefault();
        } else {
            let dataArray = {
                'name' : e.target.cd_AGEGROUP.value,
                'value' : e.target.cd_ACTIVITYNUMBER.value
            }

            CreateTracker(dataArray);

            let element = document.getElementById('largePopup');
            element.classList.remove('active');
        }
    }

    function removeValidationOnChange(e) {
        let id = e.target.getAttribute('data-validation');
        if(e.target.value !== "" || e.target.checked) {
            document.getElementById(id).classList.remove("large-popup--invalid__active");
        }
    }

    function closeFeedbackForm() {
        let element = document.getElementById('largePopup');
        element.classList.remove('active');
    }

    let langArray = englishArray;
    let welshYesNo = "";
    if(lang === 'cy') {
        langArray = welshArray;
        welshYesNo = " large-popup--form--yes-no__cy";
    }

    let redirectUrl = '/';
    if (typeof window !== 'undefined') {
        redirectUrl = window.location.href;
    }

    return (
        <section className={'large-popup'} id={'largePopup'}>
            <Container bsPrefix={'wrap'} fluid={false}>
                <Row className={'pb-md-5'}>
                    <Col xs={12} md={10} className={'mx-auto my-md-5 large-popup--container'}>
                        <button type={'button'} className={'large-popup--close'} data-id={'#largePopup'} onClick={() => closeFeedbackForm()}>
                            <span>Close</span>
                        </button>
                        <h2 className={'large-popup--header mb-4'}>{langArray.title}</h2>
                        <p className={'large-popup--content mb-4'}>
                            {langArray.para}
                        </p>
                        <form id={'feedbackForm'} className={'large-popup--form'} data-resource={'/en/'} method={'post'} noValidate={true} autoComplete={'off'} onSubmit={(e) => submitForm(e, 'feedbackForm')}>
                            <input type={'hidden'} name={'email'} value={randomEmailAddress()} />
                            <input type={'hidden'} name={'cd_AGEGROUP'} />
                            <input type={'hidden'} name={'cd_ACTIVITYNUMBER'} />
                            <input type={'hidden'} name={'cd_ACTIVITYTITLE'} />
                            <input type={'hidden'} name={'userid'} value={'321868'} />
                            <input type={'hidden'} name={'SIG3f5416d8af875c58d82f521a1e3685011b4067f3cc2ecf6e160e3ac88243575b'} />
                            <input type={'hidden'} name={'addressbookid'} value={'6118'} />
                            <input type={'hidden'} name={'ReturnURL'} value={redirectUrl} />
                            <input type={'hidden'} name={'ci_consenturl'} />
                            <div className={'large-popup--form--row'}>
                                <div className={'large-popup--form--label'}>{langArray.label1}</div>
                                <Row>
                                    <Col xs={12} sm={8} className={'offset-sm-2 mb-2'}>
                                        <Row>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating1'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={1} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating1'} className={'large-popup--form--checkbox'}>
                                                    <span>1</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating2'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={2} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating2'} className={'large-popup--form--checkbox'}>
                                                    <span>2</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating3'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={3} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating3'} className={'large-popup--form--checkbox'}>
                                                    <span>3</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating4'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={4} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating4'} className={'large-popup--form--checkbox'}>
                                                    <span>4</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating5'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={5} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating5'} className={'large-popup--form--checkbox'}>
                                                    <span>5</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'rating6'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYENGAGEMENT'} value={6} data-validation={'activityEngagement'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'rating6'} className={'large-popup--form--checkbox'}>
                                                    <span>6</span>
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={8} className={'offset-sm-2 large-popup--form--help'}>
                                        <Row>
                                            <Col xs={4} className={'font-weight-bold'}>{langArray.bad}</Col>
                                            <Col xs={4} className={'text--center font-weight-bold'}>{langArray.good}</Col>
                                            <Col xs={4} className={'text--right font-weight-bold'}>{langArray.excellent}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className={'large-popup--invalid'} id={'activityEngagement'}>
                                Please rate the children’s engagement with the activity.
                            </div>
                            <div className={'large-popup--form--row mt-4'}>
                                <div className={'large-popup--form--label'}>{langArray.label2}</div>
                                <Row>
                                    <Col xs={12} sm={8} className={'offset-sm-2 mb-2'}>
                                        <Row>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational1'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={1} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational1'} className={'large-popup--form--checkbox'}>
                                                    <span>1</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational2'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={2} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational2'} className={'large-popup--form--checkbox'}>
                                                    <span>2</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational3'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={3} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational3'} className={'large-popup--form--checkbox'}>
                                                    <span>3</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational4'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={4} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational4'} className={'large-popup--form--checkbox'}>
                                                    <span>4</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational5'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={5} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational5'} className={'large-popup--form--checkbox'}>
                                                    <span>5</span>
                                                </label>
                                            </div>
                                            <div className={'col text--center'}>
                                                <input type={'radio'} id={'ratingeducational6'} className={'large-popup--form--hidden-checkbox'} name={'cd_ACTIVITYVALUE'} value={6} data-validation={'activityValue'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'ratingeducational6'} className={'large-popup--form--checkbox'}>
                                                    <span>6</span>
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={8} className={'offset-sm-2 large-popup--form--help'}>
                                        <Row>
                                            <Col xs={4} className={'font-weight-bold'}>{langArray.bad}</Col>
                                            <Col xs={4} className={'text--center font-weight-bold'}>{langArray.good}</Col>
                                            <Col xs={4} className={'text--right font-weight-bold'}>{langArray.excellent}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className={'large-popup--invalid'} id={'activityValue'}>
                                Please rate the educational value of the activity.
                            </div>
                            <div className={'large-pop--form--row mt-4'}>
                                <div className={'large-popup--form--label'}>{langArray.label3}</div>
                                <Row>
                                    <Col xs={12} sm={8} className={'offset-sm-2'}>
                                        <Row>
                                            <Col xs={4} className={'offset-2 text--center'}>
                                                <input type={'radio'} id={'recommend-yes'} className={'large-popup--form--yes-no--checkbox'} name={'cd_RECOMMEND'} value={1} data-validation={'recommendChoice'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'recommend-yes'} className={'large-popup--form--yes-no'}>
                                                    <span>{langArray.yes}</span>
                                                </label>
                                            </Col>
                                            <Col xs={4} className={'text--center'}>
                                                <input type={'radio'} id={'recommend-no'} className={'large-popup--form--yes-no--checkbox'} name={'cd_RECOMMEND'} value={0} data-validation={'recommendChoice'} onChange={event => removeValidationOnChange(event)} />
                                                <label htmlFor={'recommend-no'} className={'large-popup--form--yes-no' + welshYesNo}>
                                                    <span>{langArray.no}</span>
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className={'large-popup--invalid'} id={'recommendChoice'}>
                                Please tell us if you would recommend this activity to others.
                            </div>
                            <div className={'large-popup--form--row mt-4'}>
                                <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                <label htmlFor={'feedbackBox'} className={'large-popup--form--label'} dangerouslySetInnerHTML={{__html: langArray.label4}} />
                                <Row>
                                    <Col xs={12}>
                                        <div className={'large-popup--form--textarea'}>
                                            <textarea name={'cd_COMMENTS'} id={'feedbackBox'} rows={4} placeholder={langArray.message} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className={'large-popup--form--row mt-4'}>
                                <Row>
                                    <Col xs={12} className={'text--center'}>
                                        <button type={'submit'} name={'submit'} className={'large-popup--form--submit'}>
                                            {langArray.submit}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Largepopup