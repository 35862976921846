function FeedbackPopup(id) {
    let element = document.getElementById(id);

    if (element.hasAttribute("data-feedback")) {
        document.querySelector('.large-popup input[name="cd_AGEGROUP"]').value = element.dataset.agegroup;
        document.querySelector('.large-popup input[name="cd_ACTIVITYNUMBER"]').value = element.dataset.title;
        document.querySelector('.large-popup input[name="cd_ACTIVITYTITLE"]').value = element.dataset.number;

        document.getElementById('largePopup').classList.add('active');
    }
}

export default FeedbackPopup;