import React from 'react'
import { graphql, Link } from "gatsby"
import {Col, Container, Row} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Largepopup from "../blocks/largepopup"
import FeedbackPopup from "../functions/feedbackpopup";
import GetTracker from "../functions/gettracker";

export const data = graphql`query LessonQuery($ageGroupBadge: String! $ageIcon: String! $ageActivityPlan: String! $ageResourcePlan: String!) {
  age: file(relativePath: {eq: $ageGroupBadge}) {
    publicURL
  }
  checkbox: file(relativePath: {eq: "SVG/single-content-checkbox.svg"}) {
    publicURL
  }
  icon: file(relativePath: {eq: $ageIcon}) {
    publicURL
  }
  activity: file(relativePath: {eq: $ageActivityPlan}) {
    publicURL
  }
  resource: file(relativePath: {eq: $ageResourcePlan}) {
    publicURL
  }
}`

let englishArray = {
    'blocks': {
        'time': 'Time',
        'resources': 'Resource level',
        'space': 'Space'
    },
    'buttons': {
        'activity': 'Activity plan',
        'learning': 'Learning resources',
        'back': 'Back to activities'
    }
}

let welshArray = {
    'blocks': {
        'time': 'Amser',
        'resources': 'Lefel adnoddau',
        'space': 'Lle'
    },
    'buttons': {
        'activity': 'Cynllun gweithgaredd',
        'learning': 'Adnoddau dysgu',
        'back': 'Yn ôl i weithgareddau'
    }
}

const LessonPage = ({ data, pageContext }) => {
    let feedbackText = "Leave feedback";
    let feedbackWelshBg = "";
    let langArray = englishArray;
    if(pageContext.lesson.lang === 'cy') {
        feedbackText = "Rhoi adborth";
        feedbackWelshBg = " single--feedback__cy";
        langArray = welshArray;
    }

    let activityProgress = "";
    let resourcesLink = "";
    if(GetTracker(pageContext.lesson.ageGroup, pageContext.lesson.activityNumber)) {
        activityProgress = (<div className={'single--checkbox'}><div className="single--checkbox--tick"></div></div>);
    } else {
        activityProgress = (<button type={'button'} id={'lesson' + pageContext.lesson.ageGroup + '-' + pageContext.lesson.activityNumber} className={'single--feedback' + feedbackWelshBg} data-feedback={'incomplete'} data-agegroup={pageContext.lesson.ageGroup} data-number={pageContext.lesson.activityNumber} onClick={() => FeedbackPopup('lesson' + pageContext.lesson.ageGroup + '-' + pageContext.lesson.activityNumber)}><span className={'activities--feedback--hidden'}>{feedbackText}</span></button>);
    }

    if(data.resource.publicURL.includes("example-resources.pdf") === false) {
        resourcesLink = (<a href={data.resource.publicURL} className={'single--button'} target={'_blank'} rel={'noreferrer'}>{langArray.buttons.learning}</a>);
    }
    return (
        <Layout lang={pageContext.lesson.translationLang} langURL={pageContext.lesson.translationURL} navLang={pageContext.lesson.transitionLang === 'en' ? 'cy' : 'en'}>
            <Seo title={pageContext.lesson.name} lang={pageContext.lesson.lang === 'en' ? `${pageContext.lesson.lang}-GB` : pageContext.lesson.lang} />
            <main className={'single'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={11} md={10} className={'mx-auto single--background mb-5'}>
                            <Row className={'pb-3'}>
                                <Col xs={6} md={4} className={'order-1 d-flex'}>
                                    {activityProgress}
                                </Col>
                                <Col xs={12} md={4} className={'text--center order-3 order-md-2 d-none d-md-flex'}>
                                    <img src={data.age.publicURL} alt={`Age ${pageContext.lesson.ageGroup}`} className={'single--icon--agegroup mx-auto'} />
                                </Col>
                                <Col xs={6} md={4} className={'text--right order-2 order-md-3 d-flex'}>
                                    <div className={'single--' + pageContext.lesson.ageGroup + '--number'}>
                                        <span>{pageContext.lesson.activityNumber}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'pb-3'}>
                                <Col xs={12} className={'pb-5 pb-md-3 pb-lg-5 single--agebadge text--center mt-4'}>
                                    <img src={data.icon.publicURL} alt={'Badge'} />
                                </Col>
                                <Col xs={12} className={'single--header text--center'}>
                                    <h1 className={'mt-0'} dangerouslySetInnerHTML={{__html: pageContext.lesson.name}} />
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={4} className={'d-flex'}>
                                            <div className={'single--time ml-0 ml-lg-auto text--center pr-0 pr-lg-4'}>
                                                <h2>{langArray.blocks.time}</h2>
                                                <p className={'mb-0'} dangerouslySetInnerHTML={{__html: pageContext.lesson.time}} />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} className={'single--border'}>
                                            <div className={'single--resource text--center'}>
                                                <h2>{langArray.blocks.resources}</h2>
                                                <p className={'mb-0'} dangerouslySetInnerHTML={{__html: pageContext.lesson.level}} />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} className={'d-flex'}>
                                            <div className={'single--space text--center mr-0 mr-lg-auto pl-lg-4 pl-0'}>
                                                <h2>{langArray.blocks.space}</h2>
                                                <p className={'mb-0'} dangerouslySetInnerHTML={{__html: pageContext.lesson.space}} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={'py-3'}>
                                        <Col xs={12} md={10} className={'text--center single--description offset-md-1'}>
                                            <p dangerouslySetInnerHTML={{__html: pageContext.lesson.description}} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={'pb-md-3 d-flex'}>
                                <Col xs={12} md={5} className={'offset-md-1 mb-3 mb-md-0'}>
                                    <a href={data.activity.publicURL} className={'single--button'} target={'_blank'} rel={'noreferrer'}>
                                        {langArray.buttons.activity}
                                    </a>
                                </Col>
                                <Col xs={12} md={5}>
                                    {resourcesLink}
                                </Col>
                            </Row>
                            <Row className={'d-flex mt-lg-5 pt-3'}>
                                <Col xs={12} md={6}>
                                    <Link to={'/' + pageContext.lesson.lang + '/' + pageContext.lesson.ageGroup + '/handbook/'} className={'single--button--back'}>
                                        <span className={'single--button--back__arrow'} />
                                        <span className={'single--button--back__text'}>
                                            {langArray.buttons.back}
                                        </span>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Largepopup lang={pageContext.lesson.lang}/>
        </Layout>
    )
}

export default LessonPage